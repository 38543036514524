<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require(`@/assets/images/logos/3hills${$vuetify.theme.dark ? '' : '-l'}.svg`)"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold uppercase">{{ $t('cms_title') }}</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">{{ $t('login_subtitle') }}</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form @submit="login" v-model="valid">
            <v-text-field
              v-model="username"
              outlined
              :label="$t('username')"
              :placeholder="$t('username_placeholder')"
              hide-details
              :rules="emailRules"
              class="mb-3"
              required
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="$t('password')"
              :placeholder="$t('password_placeholder')"
              :rules="passwordRules"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            <span class="d-block error--text" v-if="error">{{ $t('wrong_credentials') }}</span>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox :label="$t('remember_me')" hide-details class="me-3 mt-1"> </v-checkbox>

              <!-- forgot link -->
              <a href="javascript:void(0)" class="mt-1"> {{ $t('forgot_password') }} </a>
            </div>

            <v-btn
              block
              color="primary"
              type="submit"
              :disabled="!valid || loading"
              :loading="loading"
              class="mt-6"
              @click="login"
            >
              {{ $t('login') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
    <!-- tree -->
    <!--    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/rocket.png"></v-img>-->

    <!-- tree  -->
    <!--    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/moon.png"></v-img>-->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref('')
    const password = ref('')
    const loading = false
    const error = false
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      username,
      password,
      socialLink,
      loading,
      error,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      passwordRules: [v => !!v || this.$t('errors.password_required')],
      emailRules: [v => !!v || this.$t('errors.email_required')],
      valid: true,
    }
  },
  methods: {
    login() {
      this.loading = true
      this.error = false
      this.$store
        .dispatch('auth/retrieveToken', {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push({ name: 'dashboard' })
        })
        .catch(() => {
          this.error = true
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
